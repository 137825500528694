@import "../../reskin/reskin.css";

.info_popup_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.info_popup_holder .icons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.info_popup_holder .icons img {
  position: static;
  margin: 0 20px;
  transform: unset;
}

.info_popup_holder {
  width: 1200px;
  height: 94vh;
  max-height: 900px;
  background: #fff;
  position: relative;
}

.info_popup_img_holder {
  width: 100%;
  height: 560px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 60px;
  padding-bottom: 15px;
  color: #fff;
}

.info_popup_img_holder::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.7));
  height: 220px;
}

.info_popup_img_holder::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: unset;
  background: rgba(0, 0, 0, 0.5);
}

.info_popup_img_holder img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  cursor: pointer;
  opacity: 0.7;
  z-index: 1;
}

.info_popup_img_holder img:hover {
  opacity: 1;
}

.info_popup_img_holder .times_icon {
  position: absolute;
  top: 35px;
  right: 45px;
  width: 60px;
  height: 60px;
  /* background: url(../../images/player_icons/closeWhite.png); */
  background-size: 50% 50%;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 9;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.info_popup_img_holder .times_icon svg {
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.info_popup_img_holder .times_icon:hover svg {
  color: var(--main);
}

.info_popup_img_holder .times_icon:hover {
  position: absolute;
  top: 35px;
  right: 45px;
  width: 60px;
  height: 60px;
  /* background: url(../../images/player_icons/closeBlue.png); */
  background-size: 50% 50%;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 9;
  cursor: pointer;
}

.info_popup_img_holder h2 {
  z-index: 1;
  color: #fff;
  font-size: 48px;
  font-weight: 300;
  text-shadow: 1px 1px 0 #3c3c3c;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.info_popup_img_holder p {
  z-index: 1;
  color: #fff;
  font-size: 16px;
}

.info_popup_body {
  display: flex;
  flex-direction: column;
  height: calc(100% - 560px);
  padding-bottom: 20px;
}

.info_popup_body_img_holder {
  padding: 20px 60px 20px 50px;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 80px;
}

.info_popup_body_img_holder img {
  max-width: 70px;
  max-height: 50px;
}

.info_popup_body .info_popup_text_btn > p {
  font-weight: 300;
  color: #323e43;
  font-size: 20px;
  line-height: 40px;
  width: 100%;
  display: block;
  padding: 0 50px 0 60px;
  overflow-y: scroll;
  height: 155px;
}

/* width */
.info_popup_body .info_popup_text_btn > p::-webkit-scrollbar {
  width: 7px;
  border-radius: 30px;
}

/* Track */
.info_popup_body .info_popup_text_btn > p::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.info_popup_body .info_popup_text_btn > p::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 30px;
}

/* Handle on hover */
.info_popup_body .info_popup_text_btn > p::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.info_popup_body button {
  width: 215px;
  font-size: 18px;
  border: 1px solid #e5e5e5;
  color: #323e43;
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px 20px 9px;
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  margin-left: 60px;
  margin-top: auto;
}

@media (max-width: 1599px) {
  .info_popup_holder {
    width: 856px;
    height: unset;
    max-height: 98vh;
  }

  .info_popup_img_holder {
    height: 400px;
    padding-left: 35px;
    padding-bottom: 30px;
  }

  .info_popup_img_holder img {
    width: 72px;
  }

  .info_popup_img_holder h2 {
    font-size: 34px;
    margin-bottom: 5px;
  }

  .info_popup_img_holder p {
    font-size: 16px;
  }

  .info_popup_body {
    height: calc(100% - 400px);
  }

  .info_popup_body_img_holder {
    padding: 17px 40px 17px 30px;
    max-height: 70px;
  }

  .info_popup_body_img_holder img {
    max-width: 50px;
    max-height: 40px;
  }

  .info_popup_body .info_popup_text_btn > p {
    font-size: 18px;
    line-height: 28px;
    padding: 0 40px 0 30px;
    height: 85px;
    margin-top: 10px;
  }

  .info_popup_body button {
    width: 120px;
    font-size: 13px;
    margin-top: 5px;
    margin-left: 30px;
  }
}

@media (max-width: 1000px) {
  .info_popup_wrapper {
    align-items: flex-start;
    padding-top: 20px;
  }

  .info_popup_holder {
    width: 600px;
    height: 500px;
    max-height: unset;
  }

  .info_popup_img_holder {
    height: 260px;
  }

  .info_popup_img_holder img {
    width: 55px;
  }

  .info_popup_img_holder .times_icon {
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
  }

  .info_popup_img_holder .times_icon:hover {
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
  }

  .info_popup_img_holder h2 {
    font-size: 22px;
  }

  .info_popup_body {
    height: calc(100% - 260px);
  }

  .info_popup_body_img_holder {
    padding: 10px 40px 10px 30px;
  }

  .info_popup_body .info_popup_text_btn > p {
    height: 100px;
  }
}

@media (max-width: 650px) {
  .info_popup_wrapper {
    align-items: center;
    padding: 0;
  }

  .info_popup_holder {
    width: 95%;
  }

  .info_popup_img_holder h2 {
    font-size: 18px;
  }

  .info_popup_body .info_popup_text_btn > p {
    font-size: 12px;
  }
}
